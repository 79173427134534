<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <h1>
            {{ $t("event_registrierung.einstieg.label_veranstalter_werden") }}
          </h1>
        </b-col>
      </b-row>
      <b-row class="p-4 bg-mellow">
        <b-col cols="12" class="pb-4">
          <h1 class="h3">{{ $t("event_registrierung.anmeldung.page_title") }}</h1>
        </b-col>
        <b-col v-if="user" cols="12" class="pb-3">
          Du bist bereits als
          <span class="font-italic">{{ user.email }}</span> angemeldet.
        </b-col>
        <b-col v-if="user" cols="12">
          <b-button @click="logoutUser" class="mr-1">Abmelden</b-button>
          <b-button
            :to="`/${$i18n.locale}/veranstalter/event-erstellen/formular`"
            variant="danger"
            >Event erstellen</b-button
          >
        </b-col>
        <b-col v-if="!user" cols="12">
          <Login />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import Login from "@/views/Formulare/Login";

export default {
  components: {
    Login,
  },
  data() {
    return {
      loginKey: 1, // Key wird verwendet um beim Component Login einen Reload zu forcieren (Änderung des Keys forciert reload)
      user: false,
    };
  },
  created() {
    this.getUserData();
  },
  methods: {
    getUserData() {
      var user = this.$store.getters["veranstalter/getUser"];
      if (user) {
        this.$router.push(`/${this.$i18n.locale}/veranstalter/uebersicht`)
      }
    },
    logoutUser() {
      this.$store
        .dispatch("veranstalter/logout")
        .then(() => {
          console.log("Benutzer abgemeldet");
        })
        .catch((err) => {
          console.log(err);
        })
        .then(() => {
          this.user = this.$store.getters["veranstalter/getUser"];
          this.loginKey++; // Forciere reload des Formulars
        });
    },
  },
};
</script>
